
import { computed, defineComponent, inject,ref, onMounted } from 'vue'
import { IonRefresher, IonRefresherContent } from '@ionic/vue';
import TestBoxGroup from '@/components/TestBoxGroup.vue'
import TestLoading from '@/components/TestLoading.vue'
import TestLoadingMobile from '@/components/TestLoadingMobile.vue'
import NoTest from '@/components/NoTest.vue'
import TermsModal from '@/components/TermsModal.vue'

import { boolean } from 'yup';

export default defineComponent({
    components: { TestBoxGroup, TestLoading, TestLoadingMobile, NoTest, IonRefresher, IonRefresherContent, TermsModal },
    setup() {
        const testsStore: any = inject('testsStore')
        const handleRefresh = async(e: any)=>{
            testsStore.state.testsState.loading = true
            await testsStore.getTests(0)
            e.target.complete();
        }

        return {
            loading: computed(() => testsStore.state.testsState.loading),
            testNumber: computed(
                () => testsStore.state.testsState.tests.length
            ),
            handleRefresh
        }
    },
})
