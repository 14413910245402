import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc22a7fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "testsContainer" }
const _hoisted_2 = { id: "loading" }
const _hoisted_3 = { id: "tests" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_TestLoading = _resolveComponent("TestLoading")!
  const _component_TestLoadingMobile = _resolveComponent("TestLoadingMobile")!
  const _component_TestBoxGroup = _resolveComponent("TestBoxGroup")!
  const _component_NoTest = _resolveComponent("NoTest")!
  const _component_master_layout = _resolveComponent("master-layout")!

  return (_openBlock(), _createBlock(_component_master_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRefresh($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content, {
            refreshingSpinner: "circles",
            pullingIcon: "arrow-dropdown"
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TestLoading, { id: "testLoadingContainer" }),
          _createVNode(_component_TestLoadingMobile, { id: "testLoadingMobileContainer" })
        ], 512), [
          [_vShow, _ctx.loading]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('ACTIVE_TESTS_FOR_YOU')), 1),
          _createVNode(_component_TestBoxGroup, {
            id: "testBoxGroupContainer",
            onOpenFocusTest: _ctx.openFocusTest
          }, null, 8, ["onOpenFocusTest"])
        ], 512), [
          [_vShow, !_ctx.loading && _ctx.testNumber != 0]
        ]),
        (!_ctx.loading && _ctx.testNumber == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_NoTest)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}