import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1df6ccf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "testBoxGroupContainer" }
const _hoisted_2 = { id: "testBoxContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TestBox = _resolveComponent("TestBox")!
  const _component_TestBoxMobile = _resolveComponent("TestBoxMobile")!
  const _component_PaginationWat = _resolveComponent("PaginationWat")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isMobileView)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.tests, (test, idx) => {
            return (_openBlock(), _createBlock(_component_TestBox, {
              key: idx,
              test: test
            }, null, 8, ["test"]))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.isMobileView)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.tests, (test, idx) => {
            return (_openBlock(), _createBlock(_component_TestBoxMobile, {
              key: idx,
              test: test
            }, null, 8, ["test"]))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isApp)
      ? (_openBlock(), _createBlock(_component_PaginationWat, {
          key: 0,
          modelValue: _ctx.page,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
          records: _ctx.totalTests,
          onPaginate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadTests($event, false)))
        }, null, 8, ["modelValue", "records"]))
      : _createCommentVNode("", true),
    (_ctx.isApp)
      ? (_openBlock(), _createBlock(_component_ion_infinite_scroll, {
          key: 1,
          onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadTests($event, true))),
          threshold: "100px",
          disabled: _ctx.isInfiniteScrollDisabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_infinite_scroll_content, {
              "loading-spinner": "bubbles",
              "loading-text": _ctx.$t('tester_loading_test')
            }, null, 8, ["loading-text"])
          ]),
          _: 1
        }, 8, ["disabled"]))
      : _createCommentVNode("", true)
  ]))
}